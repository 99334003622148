<template>
  <div class="tei-preview">
    <!-- eslint-disable-next-line -->
    <div class="preview-content" v-html="transformedXml" />
  </div>
</template>

<script>
import teiTransformXSL from "!raw-loader!@/resources/tei-transform.xsl";
import teiToRundataXSL from "!raw-loader!@/resources/tei-to-rundata.xsl";

export default {
  name: "TeiPreview",
  props: {
    text: {
      type: String,
      default: ""
    },
    previewType: {
      type: String,
      default: "html"
    },
    noPreviewText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      $_xslProcessor: null
    };
  },
  computed: {
    $_asXMLDocument: function() {
      return "<runes>" + this.text + "</runes>";
    },
    transformedXml() {
      if (this.text && this.text.trim() !== "") {
        let parser = new DOMParser();
        let runeDoc = parser.parseFromString(this.$_asXMLDocument, "text/xml");
        let runesHtml = this.$_xslProcessor.transformToFragment(
          runeDoc,
          document
        );
        if (runesHtml) {
          //Append to DOM just to get a String version of the DOM Fragment
          let el = document.createElement("p");
          el.appendChild(runesHtml);
          let stringValue = el.innerHTML;
          if (stringValue.indexOf("error") > -1) {
            return this.noPreviewText;
          }
          return stringValue;
        }
      }
      return "";
    }
  },
  created: function() {
    let parser = new DOMParser();
    let xslt = teiToRundataXSL;
    switch (this.previewType) {
      case "html":
        xslt = teiTransformXSL;
        break;
      default:
        break;
    }

    let xslDoc = parser.parseFromString(xslt, "text/xml");
    this.$_xslProcessor = new XSLTProcessor();
    this.$_xslProcessor.importStylesheet(xslDoc);
  }
};
</script>

<style lang="scss">
.tei-preview {
  .supplied {
    opacity: 0.4;
  }
  .gap {
    color: red;
    margin-right: 5px;
  }
  .name {
    font-style: italic;
  }
  .word {
    margin-right: 5px;
  }
}
</style>
